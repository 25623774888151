import { formatCurrency } from "../utils/numberUtils";

// Função externa para 6 via WhatsApp
export const handleEnviarPedido = (pedidoData) => {
    const { nomeCliente, pedidoItens, tipoPagamento, tipoEntrega, valorTotal, taxaEntrega } = pedidoData;
  
    // Define os dados de entrega, se o tipo de entrega for "Delivery"
    let dadosEntrega = tipoEntrega.descricao === "Delivery" ? `
    ▶ DADOS PARA ENTREGA
        Endereço: ${pedidoData.enderecoCliente}
        Taxa de Entrega: *${formatCurrency(taxaEntrega)}*` : '';
      let subTotal = tipoEntrega.descricao === "Delivery" ? `
        SUBTOTAL: *${formatCurrency(valorTotal)}*      
        ------------------------------------------` : '';
    // Monta a mensagem do pedido
    const mensagem = `Olá, seu pedido está sendo preparado!        
    Previsao de Conclusão: *${new Date(pedidoData.dataHoraPrevisao).toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
    })}*
      
*✅ RESUMO DO PEDIDO Nº ${pedidoData.id}*
        Cliente: ${nomeCliente}
        ${pedidoItens.map((item) => 
          `1x ${item.produto.descricao.toUpperCase()} (${formatCurrency(item.valor * item.qtde)})` +
          (item.observacao ? `\nObs: ${item.observacao}` : '') // Adiciona observação se estiver preenchida
        ).join('\n')}
        ------------------------------------------      
  Modo de Entrega: *${tipoEntrega.descricao}*${dadosEntrega}
        -  -  -  -  -  -  -  -  -  -  - -  -  -  -${subTotal}      
        ▶ *TOTAL = ${formatCurrency(valorTotal + taxaEntrega)}*
        ------------------------------------------  
        ▶ *PAGAMENTO ${tipoPagamento.descricao.toUpperCase()}*
      `;
  
    let phoneNumber = '';
    if (pedidoData.cliente !== null) {
      phoneNumber = '&phone=+55 ' + pedidoData.cliente.telefone;
    }
  
    // Gera a URL do WhatsApp com a mensagem codificada
    const whatsappUrl = `whatsapp://send?text=${encodeURIComponent(mensagem)}${encodeURIComponent(phoneNumber)}`;
  
    return whatsappUrl;
  };
  