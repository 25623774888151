import React, { useState, useEffect } from 'react';
import './DashPedido.css';
import { fetchData } from '../../services/Api';
import { formatTime } from '../../utils/dateTimeUtils';
import ModalDetalhePedido from './ModalDetalhePedido';
import FormVazio from '../../components/form/FormVazio';
import CadPedido from './CadPedido';
import WhatsAppShareButton from '../../components/WhatsAppShareButton';

const DashPedido = () => {
  const [pedidos, setPedidos] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedPedido, setSelectedPedido] = useState(null);
  const [isCadastroOpen, setIsCadastroOpen] = useState(false);
  const [isFiltroModalOpen, setIsFiltroModalOpen] = useState(false); // Estado para o modal de filtro
  const [dataInicio, setDataInicio] = useState('');
  const [dataFim, setDataFim] = useState('');

  useEffect(() => {

    
    
    fetchPedidos();
    //const intervalId = setInterval(() => {
    //  fetchPedidos();
    //}, 5000);

    //return () => clearInterval(intervalId);
  }, []); // Filtro de datas

  const fetchPedidos = async () => {
    try {
      
      let pedidosData;
      if (dataInicio && dataFim)
      {
        // Faz a chamada da API com os parâmetros ajustados
        pedidosData = await fetchData('/Pedido/GetDashPedido', {
          dataHoraI: dataInicio,
          dataHoraF: dataFim,
        });
      }
      else
      {
        // Obtém a data e a hora atuais
        const now = new Date();
      
        // Formata a data no fuso horário local no formato YYYY-MM-DD
        const formatDateLocal = (date) => {
          return date.toLocaleDateString('en-CA'); // Formato YYYY-MM-DD
        };

        // Configura dataHoraF para a data atual no fuso horário local
        const dataHoraF = formatDateLocal(now);

        // Verifica se o horário atual é entre 00:00 e 07:00
        if (now.getHours() >= 0 && now.getHours() < 7) {
          // Se sim, pega o dia anterior para dataHoraI
          now.setDate(now.getDate() - 1);
        }

        // Configura dataHoraI para a data (atual ou anterior) no fuso horário local
        const dataHoraI = formatDateLocal(now);

        // Faz a chamada da API com os parâmetros ajustados
        pedidosData = await fetchData('/Pedido/GetDashPedido', {
          dataHoraI,
          dataHoraF,
        });
      }
    
      
  
      // Atualiza o estado com os dados recebidos
      setPedidos(pedidosData);
      setIsLoading(false);
    } catch (error) {
      setError(error);
      setIsLoading(false);
    }
  };

  const openCadastroInc = async () => {
    setSelectedPedido(null);
    setIsCadastroOpen(true);
  };

  const openCadastroAlt = async (pedidoId) => {
    const pedido = await getPedidoById(pedidoId);
    setSelectedPedido(pedido);
    setIsCadastroOpen(true);
  };

  const closeCadastro = () => {
    setIsCadastroOpen(false);
    setSelectedPedido(null);
    fetchPedidos();
  };

  const handleRequest = async (statusPedidoId) => {
    if (statusPedidoId === null || statusPedidoId === undefined) {
      alert('Status do pedido não encontrado.');
      return;
    }
  };

  const getPedidoById = (pedidoId) => {
    return fetchData('/Pedido/GetPedidoById', { pedidoId });
  };

  const openModal = async (pedidoId) => {
    try {
      if (pedidoId !== 0) {
        const pedido = await getPedidoById(pedidoId);
        setSelectedPedido(pedido);
      }
      setIsModalOpen(true);
    } catch (error) {
      console.error('Erro ao buscar detalhes do pedido:', error);
    }
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedPedido(null);
    fetchPedidos();
  };

  const openFiltroModal = () => {
    setIsFiltroModalOpen(true);
  };

  const closeFiltroModal = () => {
    setIsFiltroModalOpen(false);
  };

  const aplicarFiltro = () => {
    setIsFiltroModalOpen(false);
    fetchPedidos();
  };

  if (isLoading) {
    return <div>Carregando...</div>;
  }

  if (error) {
    return <div>Erro ao carregar os dados: {error.message}</div>;
  }

  const caixaData = [
    { status: 'Em análise', bgColor: 'bg-danger', icon: 'ion ion-pie-graph' },
    { status: 'Em Produção', bgColor: 'bg-warning', icon: 'ion ion-person-add' },
    { status: 'Aguardando Retirada/Entrega', bgColor: 'bg-info', icon: 'ion ion-bag' },
    { status: 'Concluído', bgColor: 'bg-success', icon: 'ion ion-stats-bars' },
  ];

  return (
    <>
      {isCadastroOpen ? (
        <CadPedido pedidoData={selectedPedido} onClose={closeCadastro} />
      ) : (
        <FormVazio cabecalho="Gestão de Pedidos" nomeLink="Pedidos">
          <div className="card-header">
            <button className="btn btn-secondary float">Voltar</button>
            <button className="btn btn-success float-right" onClick={() => openModal(0)}>Incluir Pedido</button>
            <button className="btn btn-primary float-right" onClick={openFiltroModal}>
              Filtrar Datas
            </button>
          </div>

          <div className="dash-pedido">
            <section className="content">
              <div className="container-fluid">
                <div className="row flex-container">
                  {caixaData.map((caixa, index) => {
                    const pedidoStatus = pedidos.find(p => p.statusPedido === caixa.status);

                    return (
                      <div className="flex-item" key={index}>
                        <div className={`small-box ${caixa.bgColor}`}>
                          <div className="inner">
                            <h3>{pedidoStatus ? pedidoStatus.qtde : 0}</h3>
                            <p>{caixa.status}</p>
                          </div>
                          <div className="icon">
                            <i className={caixa.icon} />
                          </div>
                          <a onClick={() => handleRequest(pedidoStatus?.id)} className="small-box-footer">
                            Ver Pedidos <i className="fas fa-arrow-circle-right" />
                          </a>
                        </div>
                        {pedidoStatus && pedidoStatus.pedidos.length > 0 && (
                          <div className="pedido-list">
                            {pedidoStatus.pedidos.map(pedido => (
                              <div key={pedido.id} className="pedido-item">
                                <div className="pedido-details">
                                  <p className="pedido-id">Nº Pedido: <b> {pedido.id}</b></p>
                                  <p className="pedido-data">{formatTime(pedido.dataHoraPedido)}</p>
                                </div>
                                <div className="pedido-details">
                                  <p>Modo Entrega: {pedido.tipoEntrega.descricao}</p>
                                  <button onClick={() => openModal(pedido.id)}>Gerenciar</button>
                                </div>
                              </div>
                            ))}
                          </div>
                        )}
                      </div>
                    );
                  })}
                </div>
              </div>
            </section>

            <ModalDetalhePedido isOpen={isModalOpen} onClose={closeModal} pedido={selectedPedido} />

            {/* Modal de Filtro de Datas */}
            {isFiltroModalOpen && (
              <div className="modal fade show">
                <div className="modal-content">
                  <h2>Filtrar por Data</h2>
                  <div>
                    <label>Data Início:</label>
                    <input
                      type="date"
                      value={dataInicio}
                      onChange={(e) => setDataInicio(e.target.value)}
                    />
                  </div>
                  <div>
                    <label>Data Fim:</label>
                    <input
                      type="date"
                      value={dataFim}
                      onChange={(e) => setDataFim(e.target.value)}
                    />
                  </div>
                  <button className="btn btn-success" onClick={aplicarFiltro}>
                    Aplicar Filtro
                  </button>
                  <button className="btn btn-secondary" onClick={closeFiltroModal}>
                    Cancelar
                  </button>
                </div>
              </div>
            )}
          </div>
        </FormVazio>
      )}
    </>
  );
};

export default DashPedido;
